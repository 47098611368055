import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
`

const Header = styled.h1`
  color: #475f4c;
  font-size: 48px;
  font-weight: 500;
  line-height: 49px;
  text-align: center;
`

const Centered = styled.p`
  text-align: center;
`

const Button = styled.button`
  margin-top: 16px;
  padding: 4px 32px !important;
  font-size: 24px;
  line-height: 1.9;
  background-color: white !important;
  border-radius: 27.5px;
`

const NotFoundPage = () => (
  <Layout>
    <Container>
      <SEO title="404: Not found" />
      <Header>
        404: <br /> Oops! Looks like this isn't a page.
      </Header>
      <br />
      <Centered>(let's buy some luggage and go on a trip)</Centered>
      <Button onClick={() => navigate('/shop')} className="WhiteGreenButton">
        {' '}
        Shop{' '}
      </Button>
    </Container>
  </Layout>
)

export default NotFoundPage
